// extracted by mini-css-extract-plugin
export var timeLineContainer = "projects-module--timeLineContainer--Wo1Bj";
export var timeLineDescription = "projects-module--timeLineDescription--NSfoU";
export var descrtptionHeading = "projects-module--descrtptionHeading--2l9YW";
export var timeLine = "projects-module--timeLine--1BLfm";
export var timeLineItemContainer = "projects-module--timeLineItemContainer--21WWP";
export var timeLineStreakContainer = "projects-module--timeLineStreakContainer--3c0CP";
export var timeLineItem = "projects-module--timeLineItem--2fe7O";
export var first = "projects-module--first--1LHb2";
export var second = "projects-module--second---NLHL";
export var third = "projects-module--third--ME6-d";
export var fourth = "projects-module--fourth--1ryVZ";
export var fifth = "projects-module--fifth--1QfnH";
export var sixth = "projects-module--sixth--3U32-";
export var seventh = "projects-module--seventh--2XGWW";
export var firstEn = "projects-module--firstEn--1PXDu";
export var secondEn = "projects-module--secondEn--20FV4";
export var thirdEn = "projects-module--thirdEn--3agQn";
export var fourthEn = "projects-module--fourthEn--xIiFX";
export var fifthEn = "projects-module--fifthEn--17TFP";
export var sixthEn = "projects-module--sixthEn--3Ce2F";
export var triangle = "projects-module--triangle--isH1a";
export var circle = "projects-module--circle--1uNq7";
export var timeLineStreakItem = "projects-module--timeLineStreakItem--3p5jV";
export var projectSpContainer = "projects-module--projectSpContainer--3lsoL";
export var projectBblSpItem = "projects-module--projectBblSpItem--xGOMX";
export var projectImageContainer = "projects-module--projectImageContainer--3y3na";
export var projectImageItem = "projects-module--projectImageItem--2GNTI";