import * as React from "react";
import NavHeader from "../components/navHeader";
import SiteFooter from "../components/footer";
import PageTitle from "../components/pageTitle";
import { StaticImage } from "gatsby-plugin-image";
import * as projectsStyle from "../styles/projects.module.scss";

const Project = () => {
  return (
    <main>
      <NavHeader />
      <PageTitle pageTitle="Projekty" />
      <div className="mainContainer">
        <div>
          <div className={projectsStyle.timeLineContainer}>
            <div className={projectsStyle.timeLine}>
              <div className={projectsStyle.timeLineStreakContainer}>
                <div className={projectsStyle.timeLineStreakItem}></div>
                <div className={projectsStyle.timeLineStreakItem}></div>
                <div className={projectsStyle.timeLineStreakItem}></div>
              </div>
              <div className={projectsStyle.timeLineItemContainer}>
                <div
                  className={projectsStyle.timeLineItem}
                  id={projectsStyle.triangle}
                ></div>
                <div
                  className={projectsStyle.timeLineItem}
                  id={projectsStyle.first}
                >
                  <span>2022</span>
                </div>
                <div
                  className={projectsStyle.timeLineItem}
                  id={projectsStyle.second}
                >
                  <span>2020</span>
                </div>
                <div
                  className={projectsStyle.timeLineItem}
                  id={projectsStyle.third}
                >
                  <span>2019</span>
                </div>
                <div
                  className={projectsStyle.timeLineItem}
                  id={projectsStyle.fourth}
                >
                  <span>2018</span>
                </div>
                <div
                  className={projectsStyle.timeLineItem}
                  id={projectsStyle.fifth}
                >
                  <span>2017</span>
                </div>
                <div
                  className={projectsStyle.timeLineItem}
                  id={projectsStyle.sixth}
                >
                  <span>2015/16</span>
                </div>
                <div
                  className={projectsStyle.timeLineItem}
                  id={projectsStyle.seventh}
                >
                  <span>2014</span>
                </div>
                <div
                  className={projectsStyle.timeLineItem}
                  id={projectsStyle.circle}
                ></div>
              </div>
            </div>
            <div className={projectsStyle.timeLineDescription}>
              <div>
                <div className={projectsStyle.descrtptionHeading}>
                  <span>2022</span>
                </div>
                <ul>
                  <li>
                    Implementácia webovej aplikácie GT_ExtendViewer s otvorenými
                    REST API a s vlastným responzívnym používateľským rozhraním
                    optimalizovaná pre použitie na mobilných zariadeniach.
                    <br />
                    Aplikácia je určená pre jednoduché zobrazenie obsahu
                    elektronickej správy (formát SKTALK), podpisových
                    kontajnerov a elektronických formulárov bez nutnosti
                    prihlasovania.
                    <br />
                    Aplikácia ďalej umožňuje zobraziť výsledky overenia
                    podpisov, stiahnutie podpísaného obsahu, stiahnutie
                    vizualizácie elektronického formulára a rôzne ďalšie
                    súvisiace funkcionality.
                  </li>
                </ul>
                <div className={projectsStyle.projectSpContainer}>
                  <div className={projectsStyle.projectBblSpItem}></div>
                </div>
              </div>
              <div>
                <div className={projectsStyle.descrtptionHeading}>
                  <span>2020</span>
                </div>
                <ul>
                  <li>
                    Nasadenie aplikačnej optimalizácie portálu ÚPVS so skokovým
                    zvýšením výkonnostného limitu ÚPVS. Aj napriek prepuknutiu
                    pandémie COVID-19 sú spracovanie transakcií a prevádzka
                    systému plynulé
                  </li>
                  <li>
                    Zabezpečenie 34 miliónov biznis transakcií procesov verejnej
                    správy za rok, mesačný rekord dosiahol 3,8 milióna
                    spracovaných biznis transakcií
                  </li>
                  <li>
                    Počet informačných systémov, integrovaných na ÚPVS, vzrástol
                    na 350 a naďalej rastie
                  </li>
                </ul>
                <div className={projectsStyle.projectSpContainer}>
                  <div className={projectsStyle.projectBblSpItem}></div>
                </div>
              </div>
              <div>
                <div className={projectsStyle.descrtptionHeading}>
                  <span>2019</span>
                </div>
                <ul>
                  <li>
                    Nasadenie technologickej optimalizácie a rozšírenia ÚPVS pre
                    zvýšenie výkonu do produkcie
                  </li>
                  <li>
                    Spracovanie 2,8 milióna biznis transakcií procesov verejnej
                    správy mesačne, takmer 30 miliónov biznis transakcií ročne
                  </li>
                </ul>
                <div className={projectsStyle.projectSpContainer}>
                  <div className={projectsStyle.projectBblSpItem}></div>
                </div>
              </div>
              <div>
                <div className={projectsStyle.descrtptionHeading}>
                  <span>2018</span>
                </div>
                <ul>
                  <li>
                    Implementácia druhej sady zmien ÚPVS na základe
                    novelizovanej legislatívy
                  </li>
                  <li>
                    Odovzdanie modulu Centrálne úradné doručovanie,
                    zabezpečujúce zjednotenie elektronických a listinných
                    procesov doručovania rozhodnutí
                  </li>
                  <li>
                    Spustenie prác na novom module ÚPVS - Module úradnej
                    komunikácie (API GW)
                  </li>
                  <li>
                    Prekročenie hranice 2 miliónov spracovaných biznis
                    transakcií za mesiac
                  </li>
                </ul>
                <div className={projectsStyle.projectSpContainer}>
                  <div className={projectsStyle.projectBblSpItem}></div>
                </div>
              </div>
              <div>
                <div className={projectsStyle.descrtptionHeading}>
                  <span>2017</span>
                </div>
                <ul>
                  <li>
                    Implementácia prvej sady zmien ÚPVS na základe novelizovanej
                    legislatívy
                  </li>
                  <li>
                    Prekročenie 10 miliónov spracovaných biznis transakcií
                  </li>
                </ul>
                <div className={projectsStyle.projectSpContainer}>
                  <div className={projectsStyle.projectBblSpItem}></div>
                </div>
              </div>
              <div>
                <div className={projectsStyle.descrtptionHeading}>
                  <span>2015/16</span>
                </div>
                <ul>
                  <li>
                    Realizácie prác pre zabezpečenie integrácie tretích
                    subjektov na ÚPVS tak, aby mohli riadne využívať jeho
                    funkcie a služby podľa dohodnutých scenárov a integračných
                    manuálov
                  </li>
                </ul>
                <div className={projectsStyle.projectSpContainer}>
                  <div className={projectsStyle.projectBblSpItem}></div>
                </div>
              </div>
              <div>
                <div className={projectsStyle.descrtptionHeading}>
                  <span>2014</span>
                </div>
                <ul>
                  <li>
                    Finálna realizácia projektu Ústredný portál verejnej správy
                    (ÚPVS) vrátane dokončenia jednotlivých modulov, testovania,
                    skúšobnej prevádzky a zavedenia do riadnej prevádzky
                  </li>
                  <li>
                    Spracovanie potrebných analýz a architektonických návrhov
                    pre projekt Elektronické služby Úradu vlády Slovenskej
                    republiky – eDemokracia a otvorená vláda (IIS eDOV)
                  </li>
                  <li>
                    Uvedenie projektu Informačný systém pre platby a evidenciu
                    správnych a súdnych poplatkov (IS PEP) ako súčasť komplexnej
                    služby E-kolok do produkčnej prevádzky
                  </li>
                </ul>
                <div className={projectsStyle.projectSpContainer}>
                  <div className={projectsStyle.projectBblSpItem}></div>
                </div>
              </div>
            </div>
          </div>
          <div className={projectsStyle.projectImageContainer}>
            <div className={projectsStyle.projectImageItem}>
              <StaticImage
                src="../images/dataGovSk.png"
                alt="data.gov.sk"
                title="data.gov.sk"
              />
            </div>
            <div className={projectsStyle.projectImageItem}>
              <StaticImage
                src="../images/slovenskoSk.png"
                alt="slovensko.sk"
                title="slovensko.sk"
              />
            </div>
          </div>
        </div>
      </div>

      <SiteFooter />
    </main>
  );
};

export default Project;
